body {
    overflow: visible !important;
}

.main-page-layout {
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

html::-webkit-scrollbar,
.main-page-layout::-webkit-scrollbar,
.order-confirmation-page::-webkit-scrollbar,
#root.Default .page-wrapper .page-content::-webkit-scrollbar,
#root.Default .page-wrapper .page-content .tab-content-container::-webkit-scrollbar {
    display: none;
}

.main-page-layout .eco-layout-desktop {
    position: relative;
}

body > iframe {
    pointer-events: none;
}